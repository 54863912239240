@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

input::placeholder, .ql-editor::before {
  color: #888;
}

input:focus, .ql-editor:focus {
  outline: none;
}

.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ddd;
}

.ql-container.ql-snow {
  border: none;
}

.custom-file-input {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 2px 6px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.custom-file-input:hover {
  background-color: #0056b3;
}

.custom-file-input input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.post-link {
  text-decoration: none;
  color: inherit;
}

.post-container {
  transition: box-shadow 0.3s ease-in-out;
}

.post-container:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.post-content {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.post-image img {
  width: 100%;
  /* max-height: 264px; */
  border-radius: 8px;
}

.post-meta {
  display: flex;
  gap: 8px;
  align-items: center;
}

.author {
  font-weight: 500;
}

.date {
  font-size: 0.875rem;
}

select {
  appearance: none;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.875rem;
  color: #374151;
}

body, html {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  background-color: #f9c152; 
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  height: 48px;
}

.footer-social-icons a {
  color: white;
  margin: 0 8px;
  transition: color 0.3s;
}

.footer-social-icons a:hover {
  color: #333;
}

.footer-text {
  text-align: center;
  color: white;
}

.loader {
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
